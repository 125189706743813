




import _ from 'lodash';
import {Component, Vue} from 'vue-property-decorator';
import AuthService from '@/services/AuthService';
import {Action, State} from 'vuex-class';
import Util from '@/utils/Util';
import QuoteService from '@/services/QuoteService';
import * as Sentry from '@sentry/browser';
import SentryService from '@/services/SentryService';
import config from '@/config';
import {APIResource} from '@/utils/APIResources';

@Component({
  name: 'SavedProposal',
})
export default class SavedProposal extends Vue {
  @State private app: any;
  @Action('app/setTargetSegment') private setTargetSegment: any;
  @Action('app/setClientId') private setClientId: any;
  @Action('app/setBundleId') private setBundleId: any;
  @Action('app/setPolicyIds') private setPolicyIds: any;
  @Action('app/reset') private reset: any;
  @Action('app/setValue') private setValue: any;
  @Action('app/setProposalNumber') private setProposalNumber: any;

  private async created() {
    this.setValue({code: 'dirty', value: false});
    this.setValue({code: 'loadingText', value: ''});
    this.setValue({code: 'isSavedProposal', value: true});
    const targetSegment = sessionStorage.getItem('targetSegment') || '';
    if (!this.app.targetSegment || this.app.originUrl !== window.location.href) {
      this.reset();
      this.setValue({code: 'isSavedProposal', value: true});
      this.setValue({code: 'originUrl', value: window.location.href});
      this.setTargetSegment({targetSegment});
      const token = _.get(this.$route, 'query.token', '');
      const clientId = _.get(this.$route, 'query.clientId', '');
      this.setClientId(clientId);
      const proposalId = _.get(this.$route, 'query.proposalId', '');
      this.setProposalNumber({proposalId});
      Sentry.configureScope((scope) => {
        scope.setTag('journeytype', 'SAVED_PROPOSAL');
      });
      if (!clientId || clientId === 'xxxxxxxx' || !token) {
        window.location.href = '/coverhub-portal/error';
      }
      const ts = targetSegment + (sessionStorage.subSegment ? '-' + sessionStorage.subSegment : '');
      AuthService.authLogin(token, clientId, 'savedProposal')
        .then((result: any) => {
          localStorage.removeItem('guest-token-' + config.environment + '-' + ts);
          sessionStorage.setItem('login-token-' + config.environment + '-' + ts, result.data.data.token);
          APIResource.setUser();
          window.location.reload();
        })
        .catch((e: any) => {
          console.error(e);
          window.location.href = `/coverhub-portal/auth/${targetSegment}/error?error=${e.message}`;
        });
    } else {
      this.setValue({code: 'originUrl', value: window.location.href});
      const proposalId = _.get(this.$route, 'query.proposalId', '');
      this.setProposalNumber({proposalId});
      const query = {savedProposal: 'true'};
      this.$router.replace({name: 'portal', params: {targetSegment: this.app.targetSegment}, query});
    }

    Util.gaLogPageView(this, `/savedProposal+${sessionStorage.subSegment ? sessionStorage.subSegment : sessionStorage.targetSegment}`);
  }

}
